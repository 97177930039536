@media screen and (max-width: 1480px){

    #Catalog{
        .Separator{
            margin-top: 10px !important;
        }
    }
}
@media only screen and (max-device-width : 1024px) {
    .SubHeadBlock{
        .block{
            padding-bottom: 100px;
        }
    }
    #HomeTopRed{
        .block{
            padding-bottom: 50px;
        }
    }

    .BtnBlock{
        position: relative !important;
        bottom: auto !important;
        left: auto !important;
        margin: 10px;
    }
    .HeadBlock{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    #Partners .text,
    #Partners .image,
    .FooterLogo{
        text-align: center !important;
    }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

}
