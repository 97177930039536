

@import url(https://fonts.googleapis.com/css?family=Roboto:300,300italic,400,400italic,500,700&subset=latin,cyrillic-ext,cyrillic);
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../bower_components/font-awesome/scss/font-awesome";
@import "mixins";


@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('../fonts/museosanscyrl_300.eot'); /* IE9 Compat Modes */
    src: url('../fonts/museosanscyrl_300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/museosanscyrl_300.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/museosanscyrl_300.woff') format('woff'), /* Pretty Modern Browsers */
        url('../fonts/museosanscyrl_300.ttf')  format('truetype'); /* Safari, Android, iOS */
}

html, body {
    padding-top: 40px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    height: 100%;
    width: 100%;
}
h1, h2, h3, h4{
    font-family: MuseoSansCyrl;
}
h2{

    text-align: center;
    text-transform: uppercase;
    span{
        width: 29px;
        height: 29px;
        display: inline-block;
        position: relative;
        left: -30px;
        top: 4px;
    }      
}
h2.yellow{
    span{
        background-color: #ffc600;
    }      
}
h2.red{
    span{
        background-color: #ed1c24;
    }      
}
.btn{
    font-size: 20px;
    text-transform: uppercase;
    border-style: solid;
    border-width: 1px;
    border-radius: none;
    background: none !important;
}
.btn-yellow{
    border-color: #ffcb16;
    &:hover{
        background: #ffcb16 !important;
        border-color: #ffcb16;
        color: #fff;
    }
}
.btn-white{
    border-color: #fff;
    &:hover{
        background: #fff !important;
        border-color: #fff;
        color: #000 !important;
    }
}
.btn-red{
    border-color: #ed1c24;
    &:hover{
        background: #ed1c24 !important;
        border-color: #ed1c24;
        color: #fff;
    }
}
.btn-black{
    border-color: #000;
    &:hover{
        background: #000 !important;
        border-color: #000;
        color: #fff;
    }
}





@import "social_buttons";
@import "navmenu";
@import "home";
@import "catalog";
@import "news";
@import "footer";
@import "media";
