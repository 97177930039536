#Logo{
    img{
        height: 45px;
    }
}
.navbar{
    border: none;   
}
.navbar-default{
    background-color: #fff;
}
.navbar{
    /*min-height: 80px;*/
}
.navbar-brand{
    padding: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
}
.navbar-nav>li>a{
    height: 80px;
    line-height: 45px;
    color: #000000;
    text-transform: uppercase;
    font-size: 20px;
    font-family: MuseoSansCyrl;
    padding-left: 15px;
    padding-right: 15px;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus{
    color: #fff !important;
    background: #ed1c24 !important;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form{
    border: none;
}
@media (max-width: 1200px) {
    .navbar-nav > li > a{
        font-size: 15px;
    }
    #Registration{
        .block{
            margin-bottom: 50px;
        }
    }
}